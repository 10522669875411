<template>
<b-container class="pt-4 overflow-auto">
  <b-row class="dashboard-container pb-4">
    <b-col class="mb-4" cols="12" md="4">
      <b-list-group class="bg-dark">
        <b-list-group-item class="bg-dark p-0" v-for="item in menu" :key="item.index">
          <button 
                  class="py-3 px-4 w-100 text-left link-white"
                  :class="{'text-primary': tabIndex === item.index}"
                  @click="tabIndex = item.index"
                >
            {{ item.title }}
          </button>
        </b-list-group-item>
        <b-list-group-item class="py-3 px-4 bg-dark">
          <b-form-checkbox @change="(res) => $store.dispatch('toogleIsWhiteTheme', res)" :checked="$store.state.isWhiteTheme" size="lg" name="check-button" switch>
            <span class="text-white">
              White theme
            </span>
          </b-form-checkbox>
        </b-list-group-item>
      </b-list-group>
    </b-col>

    <b-col cols="12" md="8">
      <b-card class="h-100 bg-dark">
        <b-tabs no-key-nav v-model="tabIndex" nav-class="d-none">
          <b-tab>
            <p class="h3 mb-3">Withdrawal</p>

            <form ref="withdrawalForm" @submit.prevent="withdrawalRequest()">
              <b-row>
                <b-col class="mb-4 mx-auto d-flex flex-column" cols="12" lg="10">
                  <p>Withdraw funds</p>

                  <b-input name="amount" class="mb-3" placeholder="Amout"></b-input>
                  <p class="small">The minimum withdrawal amount is 100.</p>
                  <b-input name="wallet_address" class="mb-3" placeholder="Wallet address"></b-input>
                  <p class="small">
                    Funds will be transferred to the specified wallet within 3-5 days. In case of incorrect details provided, the organizers are not responsible for any losses.
                  </p>

                  <b-btn class="ml-auto" type="submit" variant="primary">
                    Withdraw
                  </b-btn>
                </b-col>
              </b-row>
            </form>

            <div class="d-flex justify-content-center" v-if="withdrawals_loading">
              <div class="d-flex">
                <b-spinner class="mr-2" />
                <p>Loading...</p>
              </div>
            </div>

            <b-card v-else class="mb-3" v-for="(withdrawal) in MY_WITHDRAWALS" :key="withdrawal.id">
              <p class="mb-0">
                <img class="mr-1" width="20" :src="require('@/assets/token-icon.svg')" alt="">
                {{withdrawal.sum}}
              </p>

              <div class="mb-2 d-flex align-items-center">
                <b-icon class="mr-2" icon="wallet"></b-icon>
                <span class="text-gray-300 d-flex align-items-center" v-if="!withdrawal.show">
                  <span v-for="(i, index) in 10" :key="index">
                    <b-icon icon="dot"></b-icon>
                  </span>
                </span>

                <span v-else>{{withdrawal.wallet_address}}</span>

                <b-btn class="ml-auto link-gray-100" @click="toggleWalletVisible(withdrawal)" size="sm" variant="link">
                  <b-icon :icon="withdrawal.show ? 'eye-slash' : 'eye'"></b-icon>
                </b-btn>
              </div>

              <p class="mb-0 h6 text-gray-200 font-weight-light">
               <span>
                <b-icon class="mr-1" icon="clock" />
                {{ dateConverter(withdrawal.request_date) }}
               </span>

               <span v-if="withdrawal.fulfilled_date !== null">
                <b-icon class="mr-1" variant="success" icon="sucess" />
                {{ dateConverter(withdrawal.fulfilled_date)  }}
               </span>
              </p>
            </b-card>
          </b-tab>

          <b-tab>
           <p class="h3 mb-3">Payments</p>

           <div class="d-flex justify-content-center" v-if="payments_loading">
              <div class="d-flex">
                <b-spinner class="mr-2" />
                <p>Loading...</p>
              </div>
            </div>

           <b-card v-else class="mb-3" v-for="payment in MY_PAYMENTS" :key="payment.date">
             <p class="mb-0">
               <img class="mr-1" width="20" :src="require('@/assets/token-icon.svg')" alt="">
               {{payment.sum}}
             </p>
             <p class="mb-0 h6 text-gray-200 font-weight-light">{{dateConverter(payment.date)}}</p>
           </b-card>
          </b-tab>

          <b-tab>
            <p class="h3 mb-3">Account</p>

            <form ref="changeNicknameForm" class="mb-4 d-flex flex-column" @submit.prevent="changeNickName">
              <p>Change nickname</p>

              <b-input name="nick_name" required autocomplete="off" v-model="new_nickname" class="mb-3" placeholder="Username"></b-input>

              <b-btn class="ml-auto" type="submit" variant="primary">
                Save
              </b-btn>
            </form>

            <form ref="changePasswordForm" class="d-flex flex-column" @submit.prevent="changePassword">
              <p>Change password</p>
              <b-input name="old_password" required autocomplete="off" type="password" class="mb-3" placeholder="Old password"></b-input>
              <b-input name="new_password" required autocomplete="off" type="password" class="mb-3" placeholder="New password"></b-input>
              <b-input name="new_password_repeat" required autocomplete="off" type="password" class="mb-3" placeholder="New password repeat"></b-input>
              <b-btn class="ml-auto" type="submit" variant="primary">
                Save
              </b-btn>
            </form>
          </b-tab>
        </b-tabs>
      </b-card>
    </b-col>
  </b-row>
</b-container>
</template>

<script>
import { payments } from "@adyen/api-library/lib/src/typings";
import {mapGetters, mapActions} from "vuex";

export default {
  name: "dashboard",

  data: () => ({
    tabIndex: 0,
    new_nickname: '',
    new_password: '',
    menu: [
      {
        title: 'Withdrawal',
        index: 0
      },

      {
        title: 'Payments',
        index: 1
      },
      
      {
        title: 'Account',
        index: 2
      },
    ],

    withdrawal_amount: '',
    withdrawal_wallet_adress: '',
    withdrawals_loading: false, 
    withdrawals: [],

    payments_loading: false,

    new_password: '',
    new_password_repeat: '',
    old_password: '',
  }),

  computed: {
    ...mapGetters(['MY_PAYMENTS', 'MY_WITHDRAWALS', 'USER_ME']),
  },

  methods: {
    ...mapActions(['get_my_payments', 'get_my_withdrawals', 'withdrawal_request']),

    toggleTheme(res) {
      console.log(res)
    },

    dateConverter(date) {
      return new Date(date).toUTCString()
    },

    toggleWalletVisible(e) {
      try {
        this.$set(e, 'show', !e.show)
      } catch(err) {

      }
    },

    withdrawalRequest() {
      const formData = new FormData(this.$refs.withdrawalForm);

      const amount = formData.get('amount')

      if(!(amount < 100)) {
        this.$store.dispatch('withdrawal_request', formData)
            .then(() => {
              this.$bvToast.toast('Your request succefuly send ;)', {
                variant: 'success',
                title: 'Yaapy',
              })
            })
      } else {
        this.$bvToast.toast('The minimum withdrawal amount is 100.', {
          variant: 'danger',
          title: 'Oops!',
        })
      }

    },
    
    changeNickName() {
      const formData = new FormData(this.$refs.changeNicknameForm)
      const nick_name = formData.get('nick_name')

      if(nick_name.length >= 5) {
        this.$store.dispatch('USERS_PUT_NICKNAME', formData)
          .then(() => {
            this.$store.dispatch('ME')
            this.$bvToast.toast('Nickname successfuly changed!', {
              variant: 'success',
              autoHideDelay: 5000,
              title: 'Success!'
            })
          })
          .catch(() => {
            this.$bvToast.toast('The attempt to change the password has encountered an error.', {
              variant: 'danger',
              autoHideDelay: 5000,
              title: 'Nickname error!'
            })
          })
      } else {
        this.$bvToast.toast('The length of the nickname must be at least 5 characters.', {
          variant: 'danger',
          autoHideDelay: 10000,
          title: 'Nickname error!'
        })
      }
    },

    changePassword() {
      const formData = new FormData(this.$refs.changePasswordForm)
      const new_password = formData.get('new_password')
      const new_password_repeat = formData.get('new_password_repeat')

      if(new_password === new_password_repeat) {
        const password_data = {
          old_password: this.old_password,
          new_password: this.new_password,
        }

        this.$store.dispatch('USERS_PUT_PASSWORD', formData)
        .then(() => {
          this.$refs.changePasswordForm.reset()
          this.$bvToast.toast('Password succesfuly changed!', {
            variant: 'success',
            autoHideDelay: 5000,
            title: 'Password success!'
          })
        }).catch((error) => {
          console.log(error)
          this.$bvToast.toast(error.response && error.response.status === 400 ? error.response.data.detail : 'The attempt to change the password has encountered an error.', {
            variant: 'danger',
            autoHideDelay: 5000,
            title: 'Password error!'
          })
        })
      } else {
        this.$bvToast.toast('Password mismatch', {
          variant: 'danger',
          autoHideDelay: 5000,
          title: 'Password error!'
        })
      }
    }
  },

  mounted() {
    this.withdrawals_loading = true
    this.payments_loading = true
    this.get_my_payments().then(() => {
      this.payments_loading = false
    })

    this.get_my_withdrawals().then((response) =>{
      this.withdrawals_loading = false
    })
    this.$store.dispatch('ME').then(res => {
      this.new_nickname = this.new_nickname + res.data.nick_name
    })
  }
}
</script>

<style scoped>
  .dashboard-container {
    min-height: 100%;
  }
</style>